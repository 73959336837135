"use client";

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Popover } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { green, grey, purple } from "@mui/material/colors";
import { Paper, Stack, useTheme, useMediaQuery } from "@mui/material";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import {
  AccountBalanceWallet,
  ArrowBack,
  CurrencyExchange,
  Notifications,
  Person4,
  Refresh,
  Redeem,
} from "@mui/icons-material";
import { formatMoney } from "@/libs/common";
import { useRouter, usePathname } from "next/navigation";
import Image from "next/image";
import { useAuthModalStore } from "@/stores/authModalStore";
import { useAppModalStore } from "@/stores/appModalStore";
import { useConfigStore } from "@/stores/configStore";
import numeral from "numeral";
import ThemedButton from "../StyledButton";
import useMe from "@/hooks/fetchers/useMe";
import { Logo } from "../logo";
import preset5 from "@/themes/presets/preset5";
import { Icon } from "@iconify/react";
import { useUserProfile } from "@/hooks";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import { useGlobalStore } from "@/stores/globalStore";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { useState } from "react";

const pages = ["กิจกรรม/ข่าวสาร", "โปรโมชั่น", "บทความ"];
const drawerWidth = 240; // Set drawer width

type RightMenu = {
  key: string;
  label: string;
  variant: "text" | "outlined" | "contained";
  color:
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning";
};
type Menu = {
  key: string;
  label: string;
  icon?: string;
};
const rightMenus: RightMenu[] = [
  {
    key: "login",
    label: "เข้าสู่ระบบ",
    variant: "outlined",
    color: "success",
  },
  {
    key: "register",
    label: "สมัครสมาชิก",
    variant: "contained",
    color: "primary",
  },
];

const settings = [
  { key: "Profile", text: "โปรไฟล์", icon: <Icon icon="mdi:person-outline" /> },
  { key: "historyAll", text: "ประวัติธุรกรรม", icon: <Icon icon="material-symbols:history" /> },
  { key: "refund", text: "ยอดเสีย", icon: <Icon icon="gridicons:refund" /> },
  { key: "affiliate", text: "แนะนำเพื่อน", icon: <Icon icon="mdi:invite" /> }, // Updated icon here
  { key: "coupon", text: "คูปอง",icon: <Icon icon="mdi:coupon-outline" /> },
  { key: "wheel", text: "กงล้อ",icon: <Icon icon="lucide:ferris-wheel" /> },
  { key: "promotion", text: "โปรโมชั่น",icon: <Icon icon="ph:gift-fill" /> },
  { key: "mysteryBox", text: "กล่องสุ่ม", icon: <Icon icon="ph:archive-bold" /> },
  { key: "changePassword", text: "เปลี่ยนรหัสผ่าน", icon: <Icon icon="mdi:id-card" /> },
  { key: "Logout", text: "ออกจากระบบ", icon: <Icon icon="mdi:logout" /> },
];


const primaryMenus: Menu[] = [
  { key: "deposit", label: "ฝากเงิน", icon: "ph:hand-deposit" },
  { key: "withdraw", label: "ถอนเงิน", icon: "bx:money-withdraw" },
];

const proMenus: Menu[] = [
  { key: "removePro", label: "ออกโปร", icon: "ph:hand-deposit" },
  { key: "withdraw", label: "ถอนเงิน", icon: "bx:money-withdraw" },
];

function LayoutAppBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [config] = useConfigStore((state) => [state.config], shallow);
  const [user, logout] = useAuthStore(
    (state) => [state.user!, state.logout],
    shallow
  );
  const [isHeaderMenuOpen, isWalletMenuOpen, openHeaderMenu] = useGlobalStore((state) => [state.isHeaderMenuOpen, state.isWalletMenuOpen, state.setOpen,], shallow)
  const isLoggedIn = user != null;
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );
  const [setOpenAppModal, setAppDataModal] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );
  const [setOpen, setData] = useAppModalStore((state) => [
    state.setOpen,
    state.setData,
  ]);
  const { refresh: refreshMe } = useMe();
  const { refresh: refreshPromotion } = usePromotionHistory();
  const {
    isLoading,
    currentBalance,
    promotionHistory,
    isBonus,
    handleRemovePro,
    isPromotionHistoryLoading,
  } = useUserProfile();

  const router = useRouter();
  const pathname = usePathname();

  const [drawerOpen, setDrawerOpen] = useState(false); // State to control drawer
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen); // Toggle drawer open/close

  const Item = styled(Box)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    textAlign: "center",
    transition: "all 0.12s ease-in-out",
    "& #icon": {
      color: theme.palette.primary.main,
      ...(theme.name === "preset3" || theme.name === "preset6" || theme.name === "preset5") && {
        color: "white",
      },
    },
    color: theme.palette.text.secondary,
    "&:hover": {
      transform: "scale(1.08)",
      cursor: "pointer",
      "& #icon-container": {
        background: theme.palette.primary.main,
      },
      "& #icon": {
        color: theme.palette.getContrastText(theme.palette.primary.main),
      },
      "& #label": {
        fontWeight: 600,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        ...(theme.name === "preset5" && {
          color: "#86c01a",
        }),
      },
    },
  }));

  const showArrowBack = pathname !== "/";

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    openHeaderMenu("MENU", true)
    setAnchorElUser(event.currentTarget);
  };
  // const handleOpenWallet = () => {
  //   ;
  // };
  const handleOpenWallet = (event: React.MouseEvent<HTMLElement>) => {
    openHeaderMenu("WALLET", true)
    setAnchorEl(event.currentTarget);
  };
  const handleCloseWallet = () => {
    openHeaderMenu("WALLET", false)
    setAnchorEl(null);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    openHeaderMenu("MENU", false)
    setAnchorElUser(null);
  };

  const handleItemClick = (key: string) => {
    if (key === "removePro") {
      handleRemovePro(); // Call the handleRemovePro function
    } else if (key === "mystery-box") {
      router.push("/mystery-box");
    } else {
      setOpen(true);
      setData(key);
    }
  };

  const handleArrowBack = () => {
    refreshMe();
    if (pathname.includes("/lobbies")) {
      router.push("/");
    } else {
      router.back();
    }
  };

  const handleRightMenuClick = (key: string) => {
    switch (key) {
      case "login":
        setOpenLoginModal(true);
        break;
      case "register":
        setAppDataModal("signUp");
        setOpenAppModal(true);
        break;
      default:
        break;
    }
  };

  const handleProfileMenuClick = (key: string) => {
    if (key == "Logout") {
      logout();
    } else {
      setOpen(true);
      setData(key);
    }
  };

  const handleToggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Tab') {
      return;
    }
    setDrawerOpen(open);
  };

  const onOpenModal = (modalType: "deposit" | "withdraw" | "removePro") => {
    if (modalType === "deposit") {
      setData("deposit");
      setOpen(true);
    } else if (modalType === "withdraw") {
      setData("withdraw");
      setOpen(true);
    } else if (modalType === "removePro") {
      handleRemovePro(); // Handle the removePro action here
    }
  };

  const current_balance = numeral(user?.balance).format("0,00.00") || 0;
  const open = Boolean(anchorEl);
  const id = open ? 'wallet-popover' : undefined;
  return (
    <AppBar
      position="fixed"
      variant="elevation"
      elevation={0}
      sx={{
        background: theme.gradient[400],
        ...((theme.name == 'preset5' || theme.name == 'preset23') && {
          // backgroundImage: `url(${theme.backgroundImage.body})`
          backgroundColor: "rgba(0,0,0,0.8)"
        }),
        ...(theme.name == 'preset16' && {
          boxShadow: '#0003 0 4px 6px -1px,#0000001f 0 2px 4px -1px'
        })
      }}
    >
      <Container maxWidth={false}
                disableGutters
                sx={{
                  width: '100%',
                  paddingLeft: '10px',
                  paddingRight: '10px',

                }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <>
            {showArrowBack && (
              <IconButton
                style={{
                  border: "1.5px solid white",
                  height: "28px",
                  width: "28px",
                }}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleArrowBack}
                color="inherit"
              >
                <ArrowBack style={{ fontSize: "18px" }} />
              </IconButton>
            )}
          </>

          {
            isMobile && (
            <>
              {!isLoggedIn && (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    {/* โลโก้หรือส่วนซ้าย */}
                    <Stack>
                      <Box py={`${theme.config.logo.appBarSpacing}px`}>
                        <Logo
                          src={config?.logo || ""}
                          height={theme.config.logo.appBarHeight}
                        />
                      </Box>
                    </Stack>

                    {/* เมนูด้านขวา */}
                    <Stack
                      direction={"row"}
                      spacing={1}
                      sx={{
                        marginRight: 0,
                        paddingRight: 0,
                      }}
                    >
                      {rightMenus.map((menu) => (
                        <Button
                          size="large"
                          variant={menu.variant}
                          key={menu.key}
                          color={menu.color}
                          onClick={() => handleRightMenuClick(menu.key)}
                          sx={
                            theme.name === 'preset14' || theme.name == 'preset33'
                              ? menu.variant === 'outlined'
                                ? {
                                  borderColor: '#ffc100',
                                  backgroundColor: '#f7ac36',
                                  color: '#fff',
                                  "&:hover": {
                                    borderColor: '#ffc100',
                                    backgroundColor: '#f7ac36',
                                    color: '#fff',
                                  },
                                }
                                : {
                                  borderColor: '#33B7FF',
                                  backgroundColor: '#33B7ff',
                                  color: '#fff',
                                  "&:hover": {
                                    borderColor: '#33B7FF',
                                    backgroundColor: '#33B7ff',
                                    color: '#fff',
                                  },
                                }
                              : theme.name === 'preset34'
                                ? menu.variant === 'outlined'
                                  ? {
                                    borderColor: '#ffc100',
                                    backgroundColor: 'transparent',
                                    color: '#fff',
                                    "&:hover": {
                                      borderColor: '#ffc100',
                                      backgroundColor: 'transparent',
                                      color: '#fff',
                                    },
                                  }
                                  : {
                                    borderColor: '#d7b556',
                                    backgroundColor: '#d7b556',
                                    color: '#fff',
                                    "&:hover": {
                                      borderColor: '#d7b556',
                                      backgroundColor: '#d7b556',
                                      color: '#fff',
                                    },
                                  }
                                : {}
                          }
                        >
                          {menu.label}
                        </Button>
                      ))}
                    </Stack>
                  </Box>
                </>
              )}
              {isLoggedIn && (
                <>
                    {/* List Menu */}
                    <Box>
                      <Tooltip title="Open settings">
                        <IconButton onClick={handleToggleDrawer(true)} sx={{ p: 0 ,color:theme.gradient[640] }}>
                          <MenuIcon />
                        </IconButton>
                      </Tooltip>
                      <Drawer anchor="left" open={drawerOpen} onClose={handleToggleDrawer(false)}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: theme.spacing(2),
                          }}
                        >
                          <Logo src={config?.logo || ""} height={theme.config.logo.appBarHeight}/>
                          </Box>
                        <List sx={{ width: 180 }}>
                          {settings.map((setting) => (
                            <ListItem
                              key={setting.key}
                              onClick={() => handleProfileMenuClick(setting.key)}
                              button
                            >
                              <IconButton edge="start" aria-label={setting.text} sx={{ color: theme.gradient[640] }}>
                                {setting.icon}
                              </IconButton>
                              <ListItemText primary={setting.text} />
                            </ListItem>
                          ))}
                        </List>
                      </Drawer>

                    </Box>
                    {/* Logo */}
                    <Box
                      sx={{
                        marginLeft: '35%',
                        ...((theme.name == 'preset36') && {
                          marginLeft: '20%', 
                        })
                        // paddingLeft: isMobile ? '0%' : '22%', // เพิ่ม padding ซ้ายมือสำหรับ mobile และ pc
                      }}>
                      <Stack>
                        <Box py={`${theme.config.logo.appBarSpacing}px`}>
                          <Logo
                            src={config?.logo || ""}
                            height={theme.config.logo.appBarHeight}
                          />
                        </Box>
                      </Stack>
                    </Box>
                    {/* Wallet */}
                    <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
                      {!isBonus && (
                        <Box display="flex" justifyContent="center" flex="1" sx={{ width: 'auto' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: '#2C2F36',
                              borderRadius: '20px',
                              padding: '8px 12px',
                              minWidth: '100px',
                              color: 'white',
                              cursor: 'pointer',
                            }}
                            onClick={handleOpenWallet}
                          >
                            <Box
                              sx={{
                                backgroundColor: '#4AD88A',
                                borderRadius: '12px',
                                padding: '4px 8px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                color: 'black',
                                marginRight: '8px',
                              }}
                            >
                              บาท
                            </Box>
                            <Typography
                              sx={{
                                fontWeight: '600',
                                fontSize: '16px',
                                marginRight: '8px',
                              }}
                            >
                              {user?.balance || 0.00}
                            </Typography>
                            <Icon icon="mingcute:down-line" style={{ fontSize: '16px', color: 'white' }} />
                          </Box>

                          <Popover
                            id={id}
                            open={isWalletMenuOpen}
                            anchorEl={anchorEl}
                            onClose={handleCloseWallet}
                            sx={{ marginTop: isMobile ? 6 : 8 }}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'left',
                            }}
                          >
                            <Box sx={{ p: 2 }}>
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{ mb: 1 }}
                                onClick={() => onOpenModal("deposit")}
                              >
                                ฝาก
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={() => onOpenModal("withdraw")}
                              >
                                ถอน
                              </Button>
                            </Box>
                          </Popover>
                        </Box>
                      )}
                      {isBonus && (
                        <Box display="flex" justifyContent="center" flex="1" sx={{ marginRight: 5, width: 'auto' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: '#2C2F36',
                              borderRadius: '20px',
                              padding: '8px 12px',
                              minWidth: '100px',
                              color: 'white',
                              cursor: 'pointer',
                            }}
                            onClick={handleOpenWallet}
                          >
                            <Box
                              sx={{
                                backgroundColor: '#d88a4a',
                                borderRadius: '12px',
                                padding: '4px 8px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                color: 'black',
                                marginRight: '8px',
                              }}
                            >
                              โปร
                            </Box>
                            <Typography
                              sx={{
                                fontWeight: '600',
                                fontSize: '16px',
                                marginRight: '8px',
                              }}
                            >
                              {user?.balance || 0.00}
                            </Typography>
                            <Icon icon="mingcute:down-line" style={{ fontSize: '16px', color: 'white' }} />
                          </Box>

                          <Popover
                            id={id}
                            open={isWalletMenuOpen}
                            anchorEl={anchorEl}
                            onClose={handleCloseWallet}
                            sx={{ marginTop: isMobile ? 6 : 8 }}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'left',
                            }}
                          >
                            <Box sx={{ p: 2 }}>
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{ mb: 1 }}
                                disabled={currentBalance > 5}
                                onClick={() => onOpenModal("removePro")}
                              >
                                ออกโปร
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={() => onOpenModal("withdraw")}
                              >
                                ถอน
                              </Button>
                            </Box>
                          </Popover>
                        </Box>
                      )}
                    </Box>
                </>
              )}
            </>
            )
          }
          {
            !isMobile && (
              <>
                <Box>
                  {isLoggedIn && (
                    <Box>
                    </Box>
                  )}
                  {!isLoggedIn && (
                    <>
                    </>
                  )}

                </Box>
                <Box >
                  {!isLoggedIn && (
                    <>
                    <Stack>
                    <Box py={`${theme.config.logo.appBarSpacing}px`} sx={{
                  paddingLeft: isMobile ? '0%' : '50%', // เพิ่ม padding ซ้ายมือสำหรับ mobile และ pc
                  ...((theme.name == 'preset36') && {
                    // backgroundImage: `url(${theme.backgroundImage.body})`
                    paddingLeft: isMobile ? '0%' : '100%', 
                  })
                }}>
                      <Logo
                        src={config?.logo || ""}
                        height={theme.config.logo.appBarHeight}
                      />
                    </Box>
                  </Stack>
                    </>
                  )}
                  {isLoggedIn && (
                    <>
                    <Stack>
                    <Box py={`${theme.config.logo.appBarSpacing}px`} sx={{
                  paddingLeft: isMobile ? '0%' : '50%', 
                  ...((theme.name == 'preset36') && {
                    // backgroundImage: `url(${theme.backgroundImage.body})`
                    paddingLeft: isMobile ? '0%' : '200%', 
                  })
                }}>
                      <Logo 
                        src={config?.logo || ""}
                        height={theme.config.logo.appBarHeight}
                      />
                    </Box>
                  </Stack>
                    </>
                  )}
                </Box>
                <Box>
                  {!isLoggedIn && (
                    <Stack direction={"row"} spacing={1}>
                      {rightMenus.map((menu) => (
                        <Button
                          size="large"
                          variant={menu.variant}
                          key={menu.key}
                          color={menu.color}
                          onClick={() => handleRightMenuClick(menu.key)}
                          sx={
                            theme.name === 'preset14' || theme.name == 'preset33'
                              ? menu.variant === 'outlined'
                                ? {
                                  borderColor: '#ffc100',
                                  backgroundColor: '#f7ac36',
                                  color: '#fff',
                                  "&:hover": {
                                    borderColor: '#ffc100',
                                    backgroundColor: '#f7ac36',
                                    color: '#fff',
                                  },
                                }
                                : {
                                  borderColor: '#33B7FF',
                                  backgroundColor: '#33B7ff',
                                  color: '#fff',
                                  "&:hover": {
                                    borderColor: '#33B7FF',
                                    backgroundColor: '#33B7ff',
                                    color: '#fff',
                                  },
                                }
                              :
                              theme.name === 'preset34'
                                ? menu.variant === 'outlined'
                                  ? {
                                    borderColor: '#ffc100',
                                    backgroundColor: 'transparent',
                                    color: '#fff',
                                    "&:hover": {
                                      borderColor: '#ffc100',
                                      backgroundColor: 'transparent',
                                      color: '#fff',
                                    },
                                  }
                                  : {
                                    borderColor: '#d7b556',
                                    backgroundColor: '#d7b556',
                                    color: '#fff',
                                    "&:hover": {
                                      borderColor: '#d7b556',
                                      backgroundColor: '#d7b556',
                                      color: '#fff',
                                    },
                                  }
                                : {}
                          }
                        >
                          {menu.label}
                        </Button>
                      ))}
                    </Stack>
                  )}
                  {isLoggedIn && (
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                      {!isBonus &&
                        <Box display="flex" justifyContent="center" flex="1" gap={2}> {/* เพิ่ม gap ระหว่างองค์ประกอบ */}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: '#2C2F36',
                              borderRadius: '20px',
                              padding: '5px 15px',
                              minWidth: '150px',
                              color: 'white',
                              cursor: 'pointer',
                              marginRight: '10px',  // เพิ่ม margin ระหว่าง Balance และปุ่ม
                            }}
                          // onClick={handleOpenWallet}
                          >
                            <Box
                              sx={{
                                backgroundColor: '#4AD88A',
                                borderRadius: '12px',
                                padding: '4px 8px',
                                fontWeight: 'bold',
                                fontSize: '15px',
                                color: 'black',
                                marginRight: '8px',
                              }}
                            >
                              บาท
                            </Box>
                            <Typography
                              sx={{
                                fontWeight: '600',
                                fontSize: '16px',
                                marginRight: '8px',
                              }}
                            >
                              {user?.balance || 0.00}
                            </Typography>
                          </Box>
                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              sx={{
                                mb: 1,
                                marginTop: "10px",
                                fontSize: "1rem",  // เพิ่มขนาดตัวอักษร
                                padding: "10px",  // เพิ่ม padding ของปุ่ม
                                minWidth: "150px",     // กำหนดความกว้างขั้นต่ำของปุ่ม
                              }}
                              onClick={() => onOpenModal("deposit")}
                            >
                              ฝาก
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              variant="outlined"
                              color="primary"
                              fullWidth
                              sx={{
                                mb: 1,
                                marginTop: "10px",
                                fontSize: "1rem",  // เพิ่มขนาดตัวอักษร
                                padding: "10px",  // เพิ่ม padding ของปุ่ม
                                minWidth: "150px",     // กำหนดความกว้างขั้นต่ำของปุ่ม
                              }}
                              onClick={() => onOpenModal("withdraw")}
                            >
                              ถอน
                            </Button>
                          </Box>
                        </Box>}
                      {isBonus &&
                        <Box display="flex" justifyContent="center" flex="1" gap={2}> {/* เพิ่ม gap ระหว่างองค์ประกอบ */}
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: '#2C2F36',
                              borderRadius: '20px',
                              padding: '5px 15px',
                              minWidth: '150px',
                              color: 'white',
                              cursor: 'pointer',
                              marginRight: '10px',  // เพิ่ม margin ระหว่าง Balance และปุ่ม
                            }}
                          // onClick={handleOpenWallet}
                          >
                            <Box
                              sx={{
                                backgroundColor: '#d88a4a',
                                borderRadius: '12px',
                                padding: '4px 8px',
                                fontWeight: 'bold',
                                fontSize: '15px',
                                color: 'black',
                                marginRight: '8px',
                              }}
                            >
                              โปร
                            </Box>
                            <Typography
                              sx={{
                                fontWeight: '600',
                                fontSize: '16px',
                                marginRight: '8px',
                              }}
                            >
                              {user?.balance || 0.00}
                            </Typography>
                          </Box>
                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              sx={{
                                mb: 1,
                                marginTop: "10px",
                                fontSize: "1rem",  // เพิ่มขนาดตัวอักษร
                                padding: "10px",  // เพิ่ม padding ของปุ่ม
                                minWidth: "150px",     // กำหนดความกว้างขั้นต่ำของปุ่ม
                              }}
                              disabled={currentBalance > 5}
                              onClick={() => onOpenModal("removePro")}
                            >
                              ออกโปร
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              variant="outlined"
                              color="primary"
                              fullWidth
                              sx={{
                                mb: 1,
                                marginTop: "10px",
                                fontSize: "1rem",  // เพิ่มขนาดตัวอักษร
                                padding: "10px",  // เพิ่ม padding ของปุ่ม
                                minWidth: "150px",     // กำหนดความกว้างขั้นต่ำของปุ่ม
                              }}
                              onClick={() => onOpenModal("withdraw")}
                            >
                              ถอน
                            </Button>
                          </Box>
                        </Box>}
                    </Box>
                  )}
                </Box>
              </>
            )
          }

        </Stack>
      </Container>
    </AppBar>
  );
}
export default LayoutAppBar;
