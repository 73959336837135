"use client";

import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import GameMenu from "./GameMenu";
import { useTheme } from "@mui/material";
import Image from "next/image";
import { useMediaQuery } from "@mui/material";
import gamelist from "@/hooks/fetchers/useProvider";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import { useAuthModalStore } from "@/stores/authModalStore";
import { Box, Grid, CircularProgress, Typography, IconButton } from "@mui/material";
import { scroller } from "react-scroll";
import { useRouter, useSearchParams } from "next/navigation";
import Button from '@mui/material/Button';
import useLaunch from "@/hooks/useLaunch";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MainMenu from "./MainMenu";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Casino, SportsSoccer, CardGiftcard, BarChart, LocalActivity, MonetizationOn } from "@mui/icons-material";
import { margin, spacing } from "@mui/system";
import { useAppModalStore } from "@/stores/appModalStore";
import "./style.css";


const sliderSettings = {
  dots: false, infinite: false,
  speed: 500,
  slidesToShow: 10,
  slidesToScroll: 3,
  display: 'flex',
  alignContent: 'start',
  justifyContent: 'start',
  alignItems: 'start',
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 5,
        rows: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        rows: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        rows: 1,
      },
    },
  ],
};


const sliderSettingsLottery = {
  swipe: false,
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 10,
  slidesToScroll: 2,
  display: 'flex',
  alignContent: 'start',
  justifyContent: 'start',
  alignItems: 'start',
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 5,
        rows: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 5,
        rows: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        rows: 1,
      },
    },
  ],
};
const CategoryBox = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(	58, 55, 79,0.8)",
  borderRadius: "20px",
  textAlign: "center",
  padding: "3px",
  paddingTop: "5px",
  fontWeight: "bold",
  fontSize: "20px",
  color: "white",
  // marginRight: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "background-color 0.3s ease-in-out",

}));

type Game = {
  category: string;
  name: string;
  code: string;
  status: boolean;
  display: boolean;
  image: string;
  image_newlobby: string;
  is_lobby: boolean;
  games_lobby: any[];
};

type GroupedArrayItem = {
  category: string;
  data: Game[];
};

const CATEGORY: { [key: string]: string } = {
  slot: "สล็อต",
  casino: "คาสิโนสด",
  lottery: "หวย",
  sport: "กีฬา",
};

const CATEGORY_ICONS: { [key: string]: JSX.Element } = {
  slot: <LocalActivity fontSize="medium"/>,
  casino: <Casino fontSize="medium" />,
  lottery: <MonetizationOn fontSize="medium" />,
  sport: <SportsSoccer fontSize="medium"  />,
};

const ItemWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  "&:hover .hoverOverlay": {
    opacity: 1,
    visibility: "visible",
  },
}));

const ItemImage = styled(Image)(({ theme }) => ({
  transition: "all 0.12s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)",
    cursor: "pointer",
  },
}));

const HoverOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0,
  borderRadius: 20,
  visibility: "hidden",
  transition: "opacity 0.3s, visibility 0.3s",
  zIndex: 2,
}));

const PlayButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  color: "white",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: theme.palette.warning.dark,
  },
}));

export default function ProvidersList() {
  const eventSliderRef = React.useRef<Slider>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>("lobby"); // Default to 'lobby' to show all games initially

  const [setCategory] = useState(0)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { launchGame } = useLaunch();
  const router = useRouter();
  const searchParams = useSearchParams();
  const category = searchParams.get("category");
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  let width: number, height: number;

  if (isMdUp) {
    width = 1667 / 8;
    height = 2417 / 8;
  } else if (isSmUp) {
    width = 1667 / 10;
    height = 2417 / 10;
  } else {
    width = 1667 / 14;
    height = 2417 / 14;
  }
  const {
    data: promotion,
    isLoading: isPromotionListLoading,
    isActive,
  } = usePromotionHistory();
  const activePromotionPlatform =
    promotion?.promotion?.platform?.split(",") || [];
  const { data: gameList, isLoading: isGameListLoading } = gamelist();
  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category === "lobby" ? "lobby" : category); // Set 'lobby' or specific category based on selection
  };
  const sortedKeys = Object.keys(CATEGORY);
  const groupedData = gameList
    ? gameList.reduce((result: any, item: any) => {
      const category = item.category;
      if (!result[category]) {
        result[category] = [];
      }
      result[category].push(item);
      return result;
    }, {})
    : {};
  const groupedArray: GroupedArrayItem[] = sortedKeys.reduce(
    (sortedArray: GroupedArrayItem[], key: string) => {
      let data = groupedData[key];
      if (data) {
        data = data.sort((a: Game, b: Game) => {
          const aIsInActivePromotionPlatform = activePromotionPlatform.includes(
            a.code
          );
          const bIsInActivePromotionPlatform = activePromotionPlatform.includes(
            b.code
          );
          if (aIsInActivePromotionPlatform && !bIsInActivePromotionPlatform) {
            return -1;
          }
          if (!aIsInActivePromotionPlatform && bIsInActivePromotionPlatform) {
            return 1;
          }
          return 0;
        });
        sortedArray.push({ category: key, data: data as Game[] });
      }
      return sortedArray;
    },
    []
  );
  React.useEffect(() => {
    if (category && groupedArray) {
      scroller.scrollTo(`category-${category}`, {
        duration: 1000,
        delay: 200,
        smooth: "easeInOutQuart",
        offset: -100,
      });
    }
  }, [category, groupedArray]);
  const [isLoggedIn] = useAuthStore((state) => [state.user!], shallow);
  const handleRightMenuClick = () => {
    setOpenLoginModal(true);
  };
  const handleProviderClick = (
    gameId: string,
    gameCode: string,
    category: string,
    platformCode: string,
    isIframe: boolean = true
  ) => {
    if (!isLoggedIn) return;
    if (isActive && ![...activePromotionPlatform].includes(gameCode)) return;
    if (["casino", "lottery", "sport"].includes(category)) {
      launchGame(gameId, isMobile, isIframe);
      return;
    }
    router.push(`lobbies/${gameCode}`);
  };
  const [setOpenLoginModal] = useAuthModalStore(
    (state) => [state.setOpenLoginModal],
    shallow
  );
  const MAX_ROWS = isMobileDevice ? 1 : 1;
  const ITEMS_PER_ROW = isMobileDevice ? 3 : 7;
  const sliderRef = React.useRef<Slider>(null);
  const maxItems = MAX_ROWS * ITEMS_PER_ROW;
  const [visibleIndices, setVisibleIndices] = React.useState<{ [key: string]: number }>({});
  const sliderRefs = React.useRef<{ [key: string]: Slider | null }>({});
  const slickNext = (category: string, totalItems: number) => {
    if (sliderRefs.current[category]) {
      sliderRefs.current[category]?.slickNext();
    }
    setVisibleIndices((prev) => ({
      ...prev,
      [category]: Math.min((prev[category] || 0) + ITEMS_PER_ROW, totalItems - ITEMS_PER_ROW),
    }));
  };
  const slickPrev = (category: string) => {
    if (sliderRefs.current[category]) {
      sliderRefs.current[category]?.slickPrev();
    }
    setVisibleIndices((prev) => ({
      ...prev,
      [category]: Math.max((prev[category] || 0) - ITEMS_PER_ROW, 0),
    }));
  };
  if (isGameListLoading) {
    return (
      <Grid sx={{ textAlign: "center" }}>
        <CircularProgress />
      </Grid>
    );
  } else {
    return (
      <Box sx={{
        flexGrow: 1, margin: '0px',
        padding: '0px',
      }}>
        {
          !isMobile && (
            <Grid container spacing={0} sx={{
              margin: '0px',
              padding: '0px',
            }}>
              <Grid item xs={12} sx={{ pt: 3 }} >
                <Slider
                  {...{
                    dots: false, // Show navigation dots
                    infinite: false, // Enable infinite scrolling
                    speed: 500, // Transition speed
                    slidesToShow: 8, // Show one and a half slides at a time
                    arrows: false,
                    alignContent: 'start',
                    justifyContent: 'start',
                    alignItems: 'start',
                    slidesToScroll: 0, // Scroll one slide at a time
                    centerMode: false, // Center the active slide
                    centerPadding: '0px', // Increase padding to center the images
                    draggable: false, // Disable dragging
                    swipeable: false, // Disable swipe gestures
                  }}
                >
                  {/* สไลด์เมนูสำหรับเลือกหมวดหมู่ */}
                  {['slot', 'casino', 'lottery', 'sport', 'lobby'].map((category) => (
                    <Box
                      key={category}
                      onClick={() => handleCategorySelect(category)}
                      sx={{
                        cursor: "pointer",
                        opacity: selectedCategory && selectedCategory !== category ? 0.5 : 1,
                        transition: "opacity 0.3s",
                      }}
                    >
                      <img
                        src={`/assets/menu/v2/menu_${category}.png`}
                        alt={CATEGORY[category]}
                        style={{
                          paddingRight: '5%',
                          width: '100%',
                          height: 'auto',
                          display: 'block',
                          borderRadius: '10px', // Optional: Add border radius for a smoother look
                        }}
                      />
                    </Box>
                  ))}
                </Slider>

              </Grid>
              <Grid container spacing={0} item xs={12} md={12}>
                {groupedArray.map((item) => {
                  console.log(item.category)
                  console.log(item.category === "lottery")
                  const totalItems = item.data.length;
                  const sliderConfig = {
                    ...(
                      item.category === "lottery"
                        ? sliderSettingsLottery
                        : sliderSettings
                    ),
                    infinite: totalItems > 4, // Disable infinite scrolling if less than 6 items
                    arrows: false, // Hide default navigation arrows
                    draggable: totalItems > 6, // Disable dragging if less than 6 items
                  };
                  if (selectedCategory !== "lobby" && item.category !== selectedCategory) return null; // Show all games if 'lobby' is selected
                  return selectedCategory !== 'lobby' ? (
                    <Grid
                      id={`category-${item.category}`}
                      key={`category-${item.category}`}
                      item
                      xs={12}
                      sx={{ pt: 3, pb: 0 }}
                    >
                      {/* {
                  category === 0 && (
                    <Typography
                      variant="h5"
                      sx={{ color: (theme) => theme.gradient[700] || "white" }}
                    >
                      {CATEGORY?.[item.category] || "~"}
                    </Typography>
                  )
                } */}
                      <Box
                        mt={-0.5}
                        borderRadius={8}
                        width={"6px"}
                        height={"4px"}
                      // bgcolor={category === 0 ? theme.palette.primary.main : 'none'}
                      />
                      <Grid
                        container
                        columns={{
                          xs: 12,
                          sm: 12,
                          md: 7,
                          lg: 7
                        }}
                        spacing={1}
                        justifyContent={"space-start"}
                        alignItems={"center"}
                      >
                        {item.data.map((game) => (
                          <Grid
                            key={game.code}
                            item
                            // xs={category === 0 ? 6 : 3}
                            // xs={category === 0 ? 3 : 2}

                            xs={4}
                            sm={4}
                            md={1}
                            lg={1}
                            position="relative"
                          >
                            <Box
                              onClick={
                                !isLoggedIn
                                  ? () => {
                                    handleRightMenuClick();
                                  }
                                  : undefined
                              }
                            >
                              <Box
                                style={{
                                  textDecoration: "none",
                                  color: theme.palette.primary.main,
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  handleProviderClick(
                                    game?.games_lobby?.[0]?.id || "",
                                    game?.code || "",
                                    game?.category || "",
                                    game?.games_lobby?.[0]?.code || "",
                                    game?.games_lobby?.[0]?.is_iframe ?? true
                                  );
                                }}
                              >
                                <ItemImage
                                  placeholder={"blur"}
                                  blurDataURL="/assets/icons/category-casino.png"
                                  src={
                                    `/assets/game_m9/${game.category}/${game.name}.webp` ||
                                    "/assets/icons/category-casino.png"
                                  }
                                  width={width}
                                  height={height}
                                  alt={game.name}
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    filter:
                                      isLoggedIn &&
                                        isActive &&
                                        !activePromotionPlatform.includes(
                                          game?.code || ""
                                        )
                                        ? "grayscale(90%)"
                                        : "grayscale(0%)",
                                  }}
                                />

                                <HoverOverlay className="hoverOverlay">
                                  <Typography sx={{ color: "white", fontWeight: "bold" }}>
                                    {game.name}
                                  </Typography>
                                  <PlayButton>Play</PlayButton>
                                </HoverOverlay>
                                <Typography>
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      id={`category-${item.category}`}
                      key={`category-${item.category}`}
                      item
                      xs={12}
                      sx={{ pt: 2, pb: 0 }}
                    >
                      <Grid container spacing={2} sx={{ pt: 0, pb: 2 }}>
                        <Grid item xs={5} md={2}>
                          <CategoryBox sx={{color:theme.gradient[640]}}>
                            {CATEGORY_ICONS[item.category] || <Casino fontSize="medium" />}
                            &nbsp;
                            <Typography sx={{color:theme.gradient[640]}}>{CATEGORY?.[item.category] || "~"}</Typography>
                          </CategoryBox>
                        </Grid>
                        <Grid item xs={7} md={10} sx={{ display: "flex", justifyContent: "end", alignItems: 'center' }}>
                          <IconButton
                            onClick={() => slickPrev(item.category)}
                            disabled={totalItems <= (isMobileDevice ? 3 : 6)}  // Disable if totalItems <= 6
                          >
                            <ArrowBackIosIcon 
                            sx={{
                              color: totalItems <= (isMobileDevice ? 3 : 6) 
                                ? theme.gradient[630] // Disabled color
                                : theme.gradient[640], // Default color
                            }} />
                          </IconButton>
                          <IconButton
                            onClick={() => slickNext(item.category, totalItems)}
                            disabled={totalItems <= (isMobileDevice ? 3 : 6)} // Disable if totalItems <= 6
                          >
                            <ArrowForwardIosIcon 
                            sx={{
                              color: totalItems <= (isMobileDevice ? 3 : 6) 
                                ? theme.gradient[630] // Disabled color
                                : theme.gradient[640], // Default color
                            }} />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Slider
                        ref={(el: Slider | null) => {
                          sliderRefs.current[item.category] = el;
                        }}
                        {...sliderConfig}

                      >
                        {item.data.map((game) => (
                          <div key={game.code}>
                            <ItemWrapper>
                              <Box
                                style={{
                                  textDecoration: "none",
                                  color: theme.palette.primary.main,
                                  textAlign: "center",
                                  margin: "0 8px",
                                }}
                                onClick={() => {
                                  if (!isLoggedIn) {
                                    handleRightMenuClick();
                                    return;
                                  }
                                  handleProviderClick(
                                    game?.games_lobby?.[0]?.id || "",
                                    game?.code || "",
                                    game?.category || "",
                                    game?.games_lobby?.[0]?.code || "",
                                    game?.games_lobby?.[0]?.is_iframe ?? true
                                  );
                                }}
                              >
                                <ItemImage
                                  placeholder={"blur"}
                                  blurDataURL="/assets/icons/category-casino.png"
                                  src={
                                    `/assets/game_m9/${game.category}/${game.name}.webp` ||
                                    "/assets/icons/category-casino.png"
                                  }
                                  width={width}
                                  height={height}
                                  alt={game.name}
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    filter:
                                      isLoggedIn &&
                                        isActive &&
                                        !activePromotionPlatform.includes(game?.code || "")
                                        ? "grayscale(100%)"
                                        : "grayscale(0%)",
                                  }}
                                />

                                {/* Hover Overlay */}
                                <HoverOverlay className="hoverOverlay">
                                  <Typography sx={{ color: "white", fontWeight: "bold" }}>
                                    {game.name}
                                  </Typography>
                                  <PlayButton>Play</PlayButton>
                                </HoverOverlay>
                              </Box>
                            </ItemWrapper>
                          </div>
                        ))}
                      </Slider>
                    </Grid>
                  );
                })}
              </Grid>

            </Grid>
          )
        }
        {
          isMobile && (
            <>
              {isLoggedIn && (
                <Grid container spacing={0} >
                  <Grid item xs={2} md={1} sx={{ paddingTop: "0px", paddingRight: "5px" }}>
                    <Box sx={{
                      position: "sticky",
                      top: 0, // Adjust as needed to define the sticky offset from the top
                      zIndex: 1000, // Ensures it stays above other content
                      borderRadius: 1,
                      backgroundColor: "#FEBE00", // Optional: Keep a background for contrast during scroll
                      // backgroundColor: "rgba(58, 55, 79, 0.5)", // Optional: Keep a background for contrast during scroll
                    }}>
                      <MainMenu />
                    </Box>
                  </Grid>
                  <Grid item spacing={0} xs={10} md={11}>
                    {/* Image Sliders*/}
                    <Grid item xs={12} sx={{ pt: 2 }} >
                      <Slider
                        {...{
                          dots: false, // Show navigation dots
                          infinite: false, // Enable infinite scrolling
                          speed: 500, // Transition speed
                          slidesToShow: 1.75, // Show one and a half slides at a time
                          arrows: false,
                          slidesToScroll: 1, // Scroll one slide at a time
                          centerMode: true, // Center the active slide
                          centerPadding: '10px', // Increase padding to center the images
                        }}
                      >
                        {/* Slider Images */}
                        {['slot', 'casino', 'lottery', 'sport', 'lobby'].map((category) => (
                          <Box
                            key={category}
                            onClick={() => handleCategorySelect(category)}
                            sx={{
                              cursor: "pointer",
                              opacity: selectedCategory && selectedCategory !== category ? 0.5 : 1,
                              transition: "opacity 0.3s",
                            }}
                          >
                            <img
                              src={`/assets/menu/v2/menu_${category}.png`}
                              alt={CATEGORY[category]}
                              style={{
                                paddingRight: '5%',
                                width: '100%',
                                height: 'auto',
                                display: 'block',
                                borderRadius: '10px', // Optional: Add border radius for a smoother look
                              }}
                            />
                          </Box>
                        ))}
                      </Slider>
                    </Grid>
                    <Grid>

                    </Grid>
                    {groupedArray.map((item) => {
                      console.log(item.category === "lottery")
                      const totalItems = item.data.length;
                      const sliderConfig = {
                        ...(
                          item.category === "lottery"
                            ? sliderSettingsLottery
                            : sliderSettings
                        ),
                        infinite: totalItems > 4, // Disable infinite scrolling if less than 6 items
                        arrows: false, // Hide default navigation arrows
                        draggable: totalItems > 6, // Disable dragging if less than 6 items
                      };
                      if (selectedCategory !== "lobby" && item.category !== selectedCategory) return null; // Show all games if 'lobby' is selected
                      return selectedCategory !== 'lobby' ? (
                        <Grid
                          id={`category-${item.category}`}
                          key={`category-${item.category}`}
                          item
                          xs={12}
                          sx={{ pt: 3, pb: 0 }}
                        >
                          {/* {
                    category === 0 && (
                      <Typography
                        variant="h5"
                        sx={{ color: (theme) => theme.gradient[700] || "white" }}
                      >
                        {CATEGORY?.[item.category] || "~"}
                      </Typography>
                    )
                  } */}
                          <Box
                            mt={-0.5}
                            borderRadius={8}
                            width={"6px"}
                            height={"4px"}
                          // bgcolor={category === 0 ? theme.palette.primary.main : 'none'}
                          />
                          <Grid
                            container
                            columns={{
                              xs: 12,
                              sm: 12,
                              md: 7,
                              lg: 7
                            }}
                            spacing={1}
                            justifyContent={"space-start"}
                            alignItems={"center"}
                          >
                            {item.data.map((game) => (
                              <Grid
                                key={game.code}
                                item
                                // xs={category === 0 ? 6 : 3}
                                // xs={category === 0 ? 3 : 2}

                                xs={4}
                                sm={4}
                                md={1}
                                lg={1}
                                position="relative"
                              >
                                <Box
                                  onClick={
                                    !isLoggedIn
                                      ? () => {
                                        handleRightMenuClick();
                                      }
                                      : undefined
                                  }
                                >
                                  <Box
                                    style={{
                                      textDecoration: "none",
                                      color: theme.palette.primary.main,
                                      textAlign: "center",
                                    }}
                                    onClick={() => {
                                      handleProviderClick(
                                        game?.games_lobby?.[0]?.id || "",
                                        game?.code || "",
                                        game?.category || "",
                                        game?.games_lobby?.[0]?.code || "",
                                        game?.games_lobby?.[0]?.is_iframe ?? true
                                      );
                                    }}
                                  >
                                    <ItemImage
                                      placeholder={"blur"}
                                      blurDataURL="/assets/icons/category-casino.png"
                                      src={
                                        `/assets/game_m9/${game.category}/${game.name}.webp` ||
                                        "/assets/icons/category-casino.png"
                                      }
                                      width={width}
                                      height={height}
                                      alt={game.name}
                                      style={{
                                        width: "100%",
                                        height: "auto",
                                        filter:
                                          isLoggedIn &&
                                            isActive &&
                                            !activePromotionPlatform.includes(
                                              game?.code || ""
                                            )
                                            ? "grayscale(90%)"
                                            : "grayscale(0%)",
                                      }}
                                    />

                                    <HoverOverlay className="hoverOverlay">
                                      <Typography sx={{ color: "white", fontWeight: "bold" }}>
                                        {game.name}
                                      </Typography>
                                      <PlayButton>Play</PlayButton>
                                    </HoverOverlay>
                                    <Typography>
                                    </Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          id={`category-${item.category}`}
                          key={`category-${item.category}`}
                          item
                          xs={12}
                          sx={{ pt: 2, pb: 0 }}
                        >
                          <Grid container spacing={2} sx={{ pt: 0, pb: 2 }}>
                            <Grid item xs={5} md={2}>
                              <CategoryBox  sx={{color:theme.gradient[640]}}>
                                {CATEGORY_ICONS[item.category] || <Casino fontSize="medium" />}
                                &nbsp;
                                <Typography  sx={{color:theme.gradient[640]}}>{CATEGORY?.[item.category] || "~"}</Typography>
                              </CategoryBox>
                            </Grid>
                            <Grid item xs={7} md={10} sx={{ display: "flex", justifyContent: "end", alignItems: 'center' }}>
                              {/* Custom navigation arrows */}
                              <IconButton
                                onClick={() => slickPrev(item.category)}
                                disabled={totalItems <= (isMobileDevice ? 3 : 6)}  // Disable if totalItems <= 6
                              >
                                <ArrowBackIosIcon 
                                sx={{
                                  color: totalItems <= (isMobileDevice ? 3 : 6) 
                                    ? theme.gradient[630] // Disabled color
                                    : theme.gradient[640], // Default color
                                }} />
                              </IconButton>
                              <IconButton
                                onClick={() => slickNext(item.category, totalItems)}
                                disabled={totalItems <= (isMobileDevice ? 3 : 6)} // Disable if totalItems <= 6
                              >
                                <ArrowForwardIosIcon 
                                sx={{
                                  color: totalItems <= (isMobileDevice ? 3 : 6) 
                                    ? theme.gradient[630] // Disabled color
                                    : theme.gradient[640], // Default color
                                }} />
                              </IconButton>
                              {/* <CategoryBox>
                            <Typography sx={{ padding: "5px 10px", }}>ทั้งหมด</Typography>
                          </CategoryBox> */}
                            </Grid>
                          </Grid>

                          <Slider
                            ref={(el: Slider | null) => {
                              sliderRefs.current[item.category] = el;
                            }}
                            {...sliderConfig}

                          >
                            {item.data.map((game) => (
                              <div key={game.code}>
                                <ItemWrapper>
                                  <Box
                                    style={{
                                      textDecoration: "none",
                                      color: theme.palette.primary.main,
                                      textAlign: "center",
                                      margin: "0 8px",
                                    }}
                                    onClick={() => {
                                      if (!isLoggedIn) {
                                        handleRightMenuClick();
                                        return;
                                      }
                                      handleProviderClick(
                                        game?.games_lobby?.[0]?.id || "",
                                        game?.code || "",
                                        game?.category || "",
                                        game?.games_lobby?.[0]?.code || "",
                                        game?.games_lobby?.[0]?.is_iframe ?? true
                                      );
                                    }}
                                  >
                                    <ItemImage
                                      placeholder={"blur"}
                                      blurDataURL="/assets/icons/category-casino.png"
                                      src={
                                        `/assets/game_m9/${game.category}/${game.name}.webp` ||
                                        "/assets/icons/category-casino.png"
                                      }
                                      width={width}
                                      height={height}
                                      alt={game.name}
                                      style={{
                                        width: "100%",
                                        height: "auto",
                                        filter:
                                          isLoggedIn &&
                                            isActive &&
                                            !activePromotionPlatform.includes(game?.code || "")
                                            ? "grayscale(100%)"
                                            : "grayscale(0%)",
                                      }}
                                    />

                                    {/* Hover Overlay */}
                                    <HoverOverlay className="hoverOverlay">
                                      <Typography sx={{ color: "white", fontWeight: "bold" }}>
                                        {game.name}
                                      </Typography>
                                      <PlayButton>Play</PlayButton>
                                    </HoverOverlay>
                                  </Box>
                                </ItemWrapper>
                              </div>
                            ))}
                          </Slider>
                        </Grid>
                      );
                    })}
                  </Grid>

                </Grid>
              )}
              {!isLoggedIn && (
                <Grid container spacing={0} item xs={12} md={12}>
                  {/* Image Sliders*/}
                  <Grid item xs={12} sx={{ pt: 2 }} >
                    <Slider
                      {...{
                        dots: false, // Show navigation dots
                        infinite: false, // Enable infinite scrolling
                        speed: 500, // Transition speed
                        slidesToShow: 1.75, // Show one and a half slides at a time
                        arrows: false,
                        slidesToScroll: 1, // Scroll one slide at a time
                        centerMode: true, // Center the active slide
                        centerPadding: '10px', // Increase padding to center the images
                      }}
                    >
                      {/* Slider Images */}
                      {['slot', 'casino', 'lottery', 'sport', 'lobby'].map((category) => (
                        <Box
                          key={category}
                          onClick={() => handleCategorySelect(category)}
                          sx={{
                            cursor: "pointer",
                            opacity: selectedCategory && selectedCategory !== category ? 0.5 : 1,
                            transition: "opacity 0.3s",
                          }}
                        >
                          <img
                            src={`/assets/menu/v2/menu_${category}.png`}
                            alt={CATEGORY[category]}
                            style={{
                              paddingRight: '5%',
                              width: '100%',
                              height: 'auto',
                              display: 'block',
                              borderRadius: '10px', // Optional: Add border radius for a smoother look
                            }}
                          />
                        </Box>
                      ))}
                    </Slider>
                  </Grid>
                  <Grid>

                  </Grid>
                  {groupedArray.map((item) => {
                    console.log(item.category === "lottery")
                    const totalItems = item.data.length;
                    const sliderConfig = {
                      ...(
                        item.category === "lottery"
                          ? sliderSettingsLottery
                          : sliderSettings
                      ),
                      infinite: totalItems > 4, // Disable infinite scrolling if less than 6 items
                      arrows: false, // Hide default navigation arrows
                      draggable: totalItems > 6, // Disable dragging if less than 6 items
                    };
                    if (selectedCategory !== "lobby" && item.category !== selectedCategory) return null; // Show all games if 'lobby' is selected
                    return selectedCategory !== 'lobby' ? (
                      <Grid
                        id={`category-${item.category}`}
                        key={`category-${item.category}`}
                        item
                        xs={12}
                        sx={{ pt: 3, pb: 0 }}
                      >
                        {/* {
                  category === 0 && (
                    <Typography
                      variant="h5"
                      sx={{ color: (theme) => theme.gradient[700] || "white" }}
                    >
                      {CATEGORY?.[item.category] || "~"}
                    </Typography>
                  )
                } */}
                        <Box
                          mt={-0.5}
                          borderRadius={8}
                          width={"6px"}
                          height={"4px"}
                        // bgcolor={category === 0 ? theme.palette.primary.main : 'none'}
                        />
                        <Grid
                          container
                          columns={{
                            xs: 12,
                            sm: 12,
                            md: 7,
                            lg: 7
                          }}
                          spacing={1}
                          justifyContent={"space-start"}
                          alignItems={"center"}
                        >
                          {item.data.map((game) => (
                            <Grid
                              key={game.code}
                              item
                              // xs={category === 0 ? 6 : 3}
                              // xs={category === 0 ? 3 : 2}

                              xs={4}
                              sm={4}
                              md={1}
                              lg={1}
                              position="relative"
                            >
                              <Box
                                onClick={
                                  !isLoggedIn
                                    ? () => {
                                      handleRightMenuClick();
                                    }
                                    : undefined
                                }
                              >
                                <Box
                                  style={{
                                    textDecoration: "none",
                                    color: theme.palette.primary.main,
                                    textAlign: "center",
                                  }}
                                  onClick={() => {
                                    handleProviderClick(
                                      game?.games_lobby?.[0]?.id || "",
                                      game?.code || "",
                                      game?.category || "",
                                      game?.games_lobby?.[0]?.code || "",
                                      game?.games_lobby?.[0]?.is_iframe ?? true
                                    );
                                  }}
                                >
                                  <ItemImage
                                    placeholder={"blur"}
                                    blurDataURL="/assets/icons/category-casino.png"
                                    src={
                                      `/assets/game_m9/${game.category}/${game.name}.webp` ||
                                      "/assets/icons/category-casino.png"
                                    }
                                    width={width}
                                    height={height}
                                    alt={game.name}
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                      filter:
                                        isLoggedIn &&
                                          isActive &&
                                          !activePromotionPlatform.includes(
                                            game?.code || ""
                                          )
                                          ? "grayscale(90%)"
                                          : "grayscale(0%)",
                                    }}
                                  />

                                  <HoverOverlay className="hoverOverlay">
                                    <Typography sx={{ color: "white", fontWeight: "bold" }}>
                                      {game.name}
                                    </Typography>
                                    <PlayButton>Play</PlayButton>
                                  </HoverOverlay>
                                  <Typography>
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        id={`category-${item.category}`}
                        key={`category-${item.category}`}
                        item
                        xs={12}
                        sx={{ pt: 2, pb: 0 }}
                      >
                        <Grid container spacing={2} sx={{ pt: 0, pb: 2}}>
                          <Grid item xs={5} md={2}>
                            <CategoryBox  sx={{color:theme.gradient[640]}}>
                              {CATEGORY_ICONS[item.category] || <Casino fontSize="medium" />}
                              &nbsp;
                              <Typography  sx={{color:theme.gradient[640]}}>{CATEGORY?.[item.category] || "~"}</Typography>
                            </CategoryBox>
                          </Grid>
                          <Grid item xs={7} md={10} sx={{ display: "flex", justifyContent: "end", alignItems: 'center' }}>
                            {/* Custom navigation arrows */}
                            <IconButton
                              onClick={() => slickPrev(item.category)}
                              disabled={totalItems <= (isMobileDevice ? 3 : 6)}  // Disable if totalItems <= 6
                            >
                              <ArrowBackIosIcon  
                              sx={{
                                color: totalItems <= (isMobileDevice ? 3 : 6) 
                                  ? theme.gradient[630] // Disabled color
                                  : theme.gradient[640], // Default color
                              }} />
                            </IconButton>
                            <IconButton 
                              onClick={() => slickNext(item.category, totalItems)}
                              disabled={totalItems <= (isMobileDevice ? 3 : 6)} // Disable if totalItems <= 6
                            >
                              <ArrowForwardIosIcon 
                                sx={{
                                  color: totalItems <= (isMobileDevice ? 3 : 6) 
                                    ? theme.gradient[630] // Disabled color
                                    : theme.gradient[640], // Default color
                                }} />
                            </IconButton>
                            {/* <CategoryBox>
                      <Typography sx={{ padding: "5px 10px", }}>ทั้งหมด</Typography>
                    </CategoryBox> */}
                          </Grid>
                        </Grid>

                        <Slider
                          ref={(el: Slider | null) => {
                            sliderRefs.current[item.category] = el;
                          }}
                          {...sliderConfig}

                        >
                          {item.data.map((game) => (
                            <div key={game.code}>
                              <ItemWrapper>
                                <Box
                                  style={{
                                    textDecoration: "none",
                                    color: theme.palette.primary.main,
                                    textAlign: "center",
                                    margin: "0 8px",
                                  }}
                                  onClick={() => {
                                    if (!isLoggedIn) {
                                      handleRightMenuClick();
                                      return;
                                    }
                                    handleProviderClick(
                                      game?.games_lobby?.[0]?.id || "",
                                      game?.code || "",
                                      game?.category || "",
                                      game?.games_lobby?.[0]?.code || "",
                                      game?.games_lobby?.[0]?.is_iframe ?? true
                                    );
                                  }}
                                >
                                  <ItemImage
                                    placeholder={"blur"}
                                    blurDataURL="/assets/icons/category-casino.png"
                                    src={
                                      `/assets/game_m9/${game.category}/${game.name}.webp` ||
                                      "/assets/icons/category-casino.png"
                                    }
                                    width={width}
                                    height={height}
                                    alt={game.name}
                                    style={{
                                      width: "100%",
                                      height: "auto",
                                      filter:
                                        isLoggedIn &&
                                          isActive &&
                                          !activePromotionPlatform.includes(game?.code || "")
                                          ? "grayscale(100%)"
                                          : "grayscale(0%)",
                                    }}
                                  />

                                  {/* Hover Overlay */}
                                  <HoverOverlay className="hoverOverlay">
                                    <Typography sx={{ color: "white", fontWeight: "bold" }}>
                                      {game.name}
                                    </Typography>
                                    <PlayButton>Play</PlayButton>
                                  </HoverOverlay>
                                </Box>
                              </ItemWrapper>
                            </div>
                          ))}
                        </Slider>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </>
          )
        }

      </Box>
    );
  }
}

